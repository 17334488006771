import React, { useState, useEffect, Suspense, lazy, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  createMuiTheme,
  ThemeProvider,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@material-ui/core';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';
import Toast from './components/Toast/Toast';
import CurrentLocationInputField from './components/CurrentLocationInputField/CurrentLocationInputField';
import { fetchUserLocation } from './redux/modules/location';
import { isKickCovidDomain } from './common/helpers';
import useStyles from './App.styles';

const Home = lazy(() => import('./pages/Home/Home'));
const Places = lazy(() => import('./pages/Places/Places'));
const Place = lazy(() => import('./pages/Place/Place'));
const AboutUs = lazy(() => import('./pages/AboutUs/AboutUs'));
const SafetyInfo = lazy(() => import('./pages/SafetyInfo/SafetyInfo'));
const Feedback = lazy(() => import('./pages/Feedback/Feedback'));
const PageNotFound = lazy(() => import('./pages/PageNotFound/PageNotFound'));

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
};

TagManager.initialize(tagManagerArgs);

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Lato'
  }
});

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const title = isKickCovidDomain() ? 'KickCOVID.us - Spread the word, not the Virus!' : 'Laukti';
  const fetchUserLocationError = useSelector((state) => state.location.error);
  const [showModal, setShowModal] = useState(false);
  const [location, setLocation] = useState('');
  const locationInputFieldRef = useRef();

  const getLoader = () => {
    return (
      <Grid container direction="row" justify="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );
  };

  const getLocationErrorMessage = () => {
    if (fetchUserLocationError && fetchUserLocationError.code === 1) {
      return (
        <Typography variant="body2">
          We understand and value your privacy, so we don’t track you or share your data. We use your location to find
          the places closest to where you are. You have the following options for sharing your location: <br />
          <ul>
            <li>Allow Laukti to automatically detect your location</li>
            <li>Manually type in zip code, city/state - you can change this at anytime</li>
            <li>Type in location as part of your search query i.e. Trader Joe's in San Francisco, CA</li>
          </ul>
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2">
          Error while detecting your location automatically. Please try again or try following options for sharing your
          location <br />
          <ul>
            <li>Manually type in zip code, city/state - you can change it at anytime</li>
            <li>Type in location as part of your search query i.e. Trader Joe's in San Francisco, CA</li>
          </ul>
        </Typography>
      );
    }
  };

  const handleOnClose = () => {
    setShowModal(false);
  };

  const handleInputChange = (value) => {
    setLocation(value);
  };

  const settingNewLocation = () => {
    handleOnClose();
    setLocation('');
  };

  const getPageContent = () => {
    return (
      <div>
        <Toast />
        <Router>
          <Suspense fallback={getLoader()}>
            <Switch>
              <Route path="/" exact render={() => <Redirect to="/home" />} />
              <Route path="/home" render={() => <Home />} />
              <Route path="/places/:query?" render={() => <Places />} />
              <Route path="/place/:id" render={() => <Place />} />
              <Route path="/about-us" render={() => <AboutUs />} />
              <Route path="/safety-info" render={() => <SafetyInfo />} />
              <Route path="/feedback" render={() => <Feedback />} />
              <Route render={() => <PageNotFound />} />
            </Switch>
          </Suspense>
        </Router>
        <Dialog open={showModal} onClose={handleOnClose}>
          <DialogContent>
            <div className={classes.locationErrorMessage}>{getLocationErrorMessage()}</div>
            <div className={classes.locationInputContainer}>
              <CurrentLocationInputField
                ref={locationInputFieldRef}
                handleInputChange={handleInputChange}
                settingNewLocation={settingNewLocation}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOnClose}>Cancel</Button>
            <Button disabled={!location.trim().length} onClick={() => locationInputFieldRef.current.setNewLocation()}>
              DONE
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  useEffect(() => {
    dispatch(fetchUserLocation());
  }, []);

  useEffect(() => {
    setShowModal(!fetchUserLocationError ? false : true);
  }, [fetchUserLocationError]);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {getPageContent()}
    </ThemeProvider>
  );
};

export default App;
