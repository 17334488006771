import { makeAPICall } from '../../common/api';
import { addToast } from './toast';
import { createToast, isKickCovidDomain } from '../../common/helpers';
import { TOAST_TYPES } from '../../common/constants';
import { setLocation, setReverseGeocodeLocation } from './location';
// import { DUMMY_PLACE } from '../../common/constants';

const isEmpty = require('lodash/isEmpty');

const FETCHING_PLACES = 'FETCHING_PLACES';
const FETCH_PLACES_SUCCESS = 'FETCH_PLACES_SUCCESS';
const FETCH_PLACES_ERROR = 'FETCH_PLACES_ERROR';
const CLEAR_FETCH_PLACES_ERROR = 'CLEAR_FETCH_PLACES_ERROR';
const SAVING_OR_UPDATING_PLACE = 'SAVING_OR_UPDATING_PLACE';
const SAVE_PLACE_SUCCESS = 'SAVE_PLACE_SUCCESS';
const SAVE_PLACE_ERROR = 'SAVE_PLACE_ERROR';
const UPDATE_PLACE_SUCCESS = 'UPDATE_PLACE_SUCCESS';
const UPDATE_PLACE_ERROR = 'UPDATE_PLACE_ERROR';
const CLEAR_SAVE_OR_UPDATE_PLACE_ERROR = 'CLEAR_SAVE_OR_UPDATE_PLACE_ERROR';

export const fetchingPlaces = (isFetching) => {
  return {
    type: FETCHING_PLACES,
    payload: isFetching
  };
};

export const fetchPlacesSuccess = (places) => {
  return {
    type: FETCH_PLACES_SUCCESS,
    payload: places
  };
};

export const fetchPlacesError = (err) => {
  return {
    type: FETCH_PLACES_ERROR,
    payload: err
  };
};

export const clearFetchPlacesError = () => {
  return {
    type: CLEAR_FETCH_PLACES_ERROR
  };
};

export const savingOrUpdatingPlace = (isSavingOrUpdating) => {
  return {
    type: SAVING_OR_UPDATING_PLACE,
    payload: isSavingOrUpdating
  };
};

export const savePlaceSuccess = (place) => {
  return {
    type: SAVE_PLACE_SUCCESS,
    payload: place
  };
};

export const savePlaceError = (err) => {
  return {
    type: SAVE_PLACE_ERROR,
    payload: err
  };
};

export const updatePlaceSuccess = (place) => {
  return {
    type: UPDATE_PLACE_SUCCESS,
    payload: place
  };
};

export const updatePlaceError = (err) => {
  return {
    type: UPDATE_PLACE_ERROR,
    payload: err
  };
};

export const clearSaveOrUpdatePlaceError = () => {
  return {
    type: CLEAR_SAVE_OR_UPDATE_PLACE_ERROR
  };
};

export const fetchPlaces = () => async (dispatch, getState) => {
  const userLocation = getState().location.location;
  const searchQuery = getState().search.searchQuery;
  let url = process.env.REACT_APP_BASE_API_URL + `places?query=${searchQuery}`;

  if (userLocation) {
    url = url + `&latitude=${userLocation.lat}&longitude=${userLocation.lon}`;
  }

  dispatch(clearFetchPlacesError());
  dispatch(fetchingPlaces(true));

  try {
    const response = await makeAPICall({ method: 'get', url: url });

    if (response.hasOwnProperty('geocode')) {
      dispatch(setLocation(response.geocode));
      dispatch(setReverseGeocodeLocation(response.formattedAddress));
    }

    dispatch(fetchPlacesSuccess(response.places));
    dispatch(fetchingPlaces(false));
  } catch (err) {
    dispatch(fetchPlacesError(err));
    dispatch(fetchingPlaces(false));
  }
};

export const fetchPlace = (id) => async (dispatch) => {
  const url = process.env.REACT_APP_BASE_API_URL + `places/${id}`;

  dispatch(clearFetchPlacesError());
  dispatch(fetchingPlaces(true));

  try {
    const response = await makeAPICall({ method: 'get', url: url });
    dispatch(fetchPlacesSuccess(isEmpty(response.place) ? [] : [response.place]));
    dispatch(fetchingPlaces(false));
  } catch (err) {
    dispatch(fetchPlacesError(err));
    dispatch(fetchingPlaces(false));
  }
};

export const savePlace = ({ id, place, additionalData }) => async (dispatch) => {
  const data = {
    place: {
      globalData: place.globalData,
      vendorId: place.vendorId,
      name: place.name,
      address: place.formatted_address,
      latitude: place.geometry.location.lat,
      longitude: place.geometry.location.lng
    }
  };

  if (id) {
    data.placeId = id;
  } else {
    data.place = {
      ...data.place,
      ...additionalData
    };
  }

  const params = {
    method: 'put',
    url: process.env.REACT_APP_BASE_API_URL + `places`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };

  dispatch(clearSaveOrUpdatePlaceError());
  dispatch(savingOrUpdatingPlace(true));

  try {
    const response = await makeAPICall(params);

    dispatch(savePlaceSuccess(response.place));
    dispatch(savingOrUpdatingPlace(false));
  } catch (err) {
    if (!id) {
      dispatch(savePlaceError(err));
      dispatch(savingOrUpdatingPlace(false));
      dispatch(
        addToast(
          createToast(TOAST_TYPES.ERROR, 'Something went wrong while submitting your updates. Please try again.')
        )
      );
    }
  }
};

export const updatePlace = (place, additionalData) => async (dispatch) => {
  const params = {
    method: 'post',
    url: process.env.REACT_APP_BASE_API_URL + `places`,
    headers: { 'Content-Type': 'application/json' },
    data: {
      placeId: place.id,
      address: place.formatted_address,
      additionalData: additionalData,
      domain: isKickCovidDomain() ? 'kickcovid' : 'laukti'
    }
  };

  dispatch(clearSaveOrUpdatePlaceError());
  dispatch(savingOrUpdatingPlace(true));

  try {
    const response = await makeAPICall(params);

    dispatch(updatePlaceSuccess(response.place));
    dispatch(savingOrUpdatingPlace(false));
  } catch (err) {
    dispatch(updatePlaceError(err));
    dispatch(savingOrUpdatingPlace(false));
    dispatch(
      addToast(createToast(TOAST_TYPES.ERROR, 'Something went wrong while submitting your updates. Please try again.'))
    );
  }
};

const initialState = {
  places: [],
  fetchingPlaces: false,
  savingOrUpdatingPlace: false,
  fetchPlacesError: null,
  saveOrUpdatePlaceError: null
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCHING_PLACES:
      return {
        ...state,
        fetchingPlaces: action.payload
      };
    case FETCH_PLACES_SUCCESS:
      return {
        ...state,
        places: action.payload
      };
    case FETCH_PLACES_ERROR:
      return {
        ...state,
        fetchPlacesError: action.payload
      };
    case CLEAR_FETCH_PLACES_ERROR:
      return {
        ...state,
        fetchPlacesError: null
      };
    case SAVING_OR_UPDATING_PLACE:
      return {
        ...state,
        savingOrUpdatingPlace: action.payload
      };
    case SAVE_PLACE_SUCCESS:
      return {
        ...state,
        places: state.places.map((p) => {
          if (p.vendorId === action.payload.vendorId) {
            p.id = action.payload.id;
            p.globalData = action.payload.globalData;
            p.totalOverallSafetyRatings = action.payload.totalOverallSafetyRatings;
            p.averageOverallSafetyRatings = action.payload.averageOverallSafetyRatings;

            if (action.payload.additionalData.length) {
              p.additionalData.unshift(action.payload.additionalData[0]);
            }
          }
          return p;
        })
      };
    case SAVE_PLACE_ERROR:
      return {
        ...state,
        saveOrUpdatePlaceError: action.payload
      };
    case UPDATE_PLACE_SUCCESS:
      return {
        ...state,
        places: state.places.map((p) => {
          if (p.id === action.payload.id) {
            p.globalData = action.payload.globalData;
            p.totalOverallSafetyRatings = action.payload.totalOverallSafetyRatings;
            p.averageOverallSafetyRatings = action.payload.averageOverallSafetyRatings;
            p.additionalData.unshift(action.payload.additionalData[0]);
          }
          return p;
        })
      };
    case UPDATE_PLACE_ERROR:
      return {
        ...state,
        saveOrUpdatePlaceError: action.payload
      };
    case CLEAR_SAVE_OR_UPDATE_PLACE_ERROR:
      return {
        ...state,
        saveOrUpdatePlaceError: null
      };
    default:
      return state;
  }
}
